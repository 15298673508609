
import {
  Component, Vue, Prop, Emit,
} from 'vue-property-decorator';
import moment from '@/services/moment/moment.service';

@Component
export default class TimePickerComponent extends Vue {
  @Prop({ type: Boolean, default: () => false })
  private readonly outlined!: boolean;

  @Prop({ type: Boolean, default: () => false })
  private readonly dense!: boolean;

  @Prop({ type: Boolean, default: () => true })
  private readonly attach!: boolean;

  @Prop({ type: Boolean, default: () => false })
  private readonly disabled!: boolean;

  @Prop({ type: Boolean, default: () => false })
  private readonly validateOnBlur!: boolean;

  @Prop({ type: Boolean, default: () => true })
  private readonly clearable!: boolean;

  @Prop({ type: String, default: () => '' })
  private readonly value!: string;

  @Prop({ type: String, default: () => '' })
  private readonly label!: string;

  @Prop({ type: String, default: () => '24hr' })
  private readonly format!: string;

  @Prop({ type: Array, default: () => [] })
  private readonly rules!: string;

  @Prop({ type: Function })
  private readonly allowedHours!: any;

  @Prop({ type: Function })
  private readonly allowedMinutes!: any;

  private openDatePicker: boolean = false;

  private setDate(date: any) {
    this.$emit('input', date);
  }

  private inputChanged(date: string = '') {
    this.setDate(date);
  }

  private openPicker() {
    this.openDatePicker = true;
  }
}
