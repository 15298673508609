import http from '@/services/http.service';

class ReminderService {
  getAllReminders() {
    return http.get('/reminder').then(({ data }) => data.data);
  }

  getRemindersByOrderId(order_id: string) {
    return http
      .get('/reminder', {
        params: {
          order_id,
        },
      })
      .then(({ data }) => data.data);
  }

  getRemindersCall(order_id: string) {
    return http
      .get('/reminder', {
        params: {
          order_id,
          type: 'CALL',
        },
      })
      .then(({ data }) => data.data);
  }

  createReminder(reminder: any) {
    return http.post('reminder/', reminder).then(({ data }) => data.data);
  }

  updateReminder(reminder: any) {
    return http.put(`reminder/${reminder.id}`, reminder).then(({ data }) => data.data);
  }

  deleteReminder(reminderId: string) {
    return http.delete(`reminder/${reminderId}`).then(({ data }) => data.data);
  }

  markAsConcluded(reminderId: string) {
    return http.patch(`reminder/${reminderId}/finished`).then(({ data }) => data.data);
  }

  markAsPending(reminderId: string) {
    return http.patch(`reminder/${reminderId}/pending`).then(({ data }) => data.data);
  }
}

export default new ReminderService();
