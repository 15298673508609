
import { Component, Vue, Prop } from 'vue-property-decorator';
import DeleteDialog from '@/components/dialogs/delete-dialog.component.vue';
import moment from '@/services/moment/moment.service';
import ReminderDialog from './dialog-reminder.component.vue';
import { User } from '../../../Administrative/modules/Users/entities/user.entity';
import { ClientReminder } from './entities/client-reminders.entity';
import { Group } from '../../../Administrative/modules/Users/entities/group.entity';
import userGroupService from '../../../Administrative/modules/Users/services/user-group.service';
import { GroupEnum } from '../../../Administrative/modules/Users/enum/group.enum';
import userService from '../../../Administrative/modules/Users/services/user.service';

@Component({ components: { ReminderDialog, DeleteDialog } })
export default class ReminderCalendar extends Vue {
  public $refs: any;

  @Prop({
    type: Array,
    default: () => [],
  })
  public readonly reminders!: any[];

  @Prop({
    type: Boolean,
    default: () => true,
  })
  public readonly showAddReminder!: boolean;

  @Prop({
    type: Boolean,
    default: () => true,
  })
  public readonly showGoToReminder!: boolean;

  public focus = moment().format('YYYY-MM-DD');

  public selectedEvent: any = {};

  public users: User[] = [];

  public selectedElement = null;

  public currentReminder: ClientReminder = new ClientReminder();

  public reminderToDelete: ClientReminder = new ClientReminder();

  public selectedOpen = false;

  public showReminderOpenDialog = false;

  public showDialogDelete = false;

  public get titleDate() {
    return `${moment(this.focus).format('MMMM')} de ${moment(this.focus).format('YYYY')}`;
  }

  public get remindersFormatted() {
    return this.reminders.map((it: ClientReminder) => it.formatToCalendar());
  }

  public markAsConcluded() {
    const reminder: ClientReminder = this.reminders.find(
      (it: ClientReminder) => it.id === this.selectedEvent.id,
    );
    reminder
      .markAsConcluded()
      .then(() => {
        this.$snackbar.open({
          text: 'Lembrete marcado como concluído',
          buttonColor: 'white',
          color: 'success',
        });
        this.$emit('get-reminders');
      })
      .catch(() => {
        this.$snackbar.open({
          text: 'Ocorreu um erro ao marcar como concluído',
          buttonColor: 'white',
          color: 'danger',
        });
      });
  }

  public markAsPending() {
    const reminder: ClientReminder = this.reminders.find(
      (it: ClientReminder) => it.id === this.selectedEvent.id,
    );
    reminder
      .markAsPending()
      .then(() => {
        this.$snackbar.open({
          text: 'Lembrete marcado como pendente',
          buttonColor: 'white',
          color: 'success',
        });
        this.$emit('get-reminders');
      })
      .catch(() => {
        this.$snackbar.open({
          text: 'Ocorreu um erro ao marcar como pendente',
          buttonColor: 'white',
          color: 'danger',
        });
      });
  }

  public editReminder() {
    const reminderToEdit = this.reminders.find(
      (it: ClientReminder) => it.id === this.selectedEvent.id,
    );
    this.currentReminder = new ClientReminder(reminderToEdit);
    this.openDialogReminder();
  }

  public goToReminder() {
    const reminderToGo = this.reminders.find(
      (it: ClientReminder) => it.id === this.selectedEvent.id,
    );
    this.$router.push({
      name: 'client-edit',
      params: {
        id: reminderToGo.order_id,
        tab: 'reminder',
      },
    });
  }

  public closeDialogDelete() {
    this.showDialogDelete = false;
  }

  public openDialogDelete() {
    this.reminderToDelete = new ClientReminder(this.selectedEvent);
    this.showDialogDelete = true;
  }

  public setToday() {
    this.focus = '2020-05-11';
  }

  public openDialogReminder() {
    this.showReminderOpenDialog = true;
  }

  public closeDialogReminder() {
    this.currentReminder = new ClientReminder();
    this.showReminderOpenDialog = false;
  }

  public prev() {
    this.$refs.calendar.prev();
  }

  public next() {
    this.$refs.calendar.next();
  }

  public showEvent(calendarItem: any) {
    const { nativeEvent, event } = calendarItem;

    const open = () => {
      this.selectedEvent = event;
      this.selectedElement = nativeEvent.target;
      setTimeout(() => {
        this.selectedOpen = true;
      }, 10);
    };

    if (this.selectedOpen) {
      this.selectedOpen = false;
      setTimeout(open, 10);
    } else {
      open();
    }

    nativeEvent.stopPropagation();
  }

  public save() {
    if (this.currentReminder.id) {
      this.updateReminder();
      return;
    }
    this.createReminder();
  }

  public updateReminder() {
    this.currentReminder
      .update()
      .then(() => {
        this.$snackbar.open({
          text: 'Lembrete alterado com sucesso',
          buttonColor: 'white',
          color: 'success',
        });
        this.$emit('get-reminders');
      })
      .catch(() => {
        this.$snackbar.open({
          text: 'Ocorreu um erro ao alterar o lembrete',
          buttonColor: 'white',
          color: 'danger',
        });
      })
      .finally(() => {
        this.closeDialogReminder();
      });
  }

  public createReminder() {
    const { id } = this.$route.params;
    this.currentReminder
      .create(id)
      .then(() => {
        this.$snackbar.open({
          text: 'Lembrete adicionado com sucesso',
          buttonColor: 'white',
          color: 'success',
        });
        this.$emit('get-reminders');
      })
      .catch(() => {
        this.$snackbar.open({
          text: 'Ocorreu um erro ao salvar o lembrete',
          buttonColor: 'white',
          color: 'danger',
        });
      })
      .finally(() => {
        this.currentReminder = new ClientReminder();
        this.closeDialogReminder();
      });
  }

  public deleteItem() {
    this.reminderToDelete
      .delete()
      .then(() => {
        this.$snackbar.open({
          text: 'Lembrete removido com sucesso',
          buttonColor: 'white',
          color: 'success',
        });
        this.$emit('get-reminders');
      })
      .catch(() => {
        this.$snackbar.open({
          text: 'Ocorreu um erro ao remover o lembrete',
          buttonColor: 'white',
          color: 'danger',
        });
      })
      .finally(() => {
        this.closeDialogDelete();
      });
  }

  public async getGroupIdPartner() {
    const groupIds: Group[] = await userGroupService.getGroups();
    const partner = groupIds.find((it: Group) => it.name === GroupEnum.PARTNER);
    return partner ? partner.id : '';
  }

  public async getUsers() {
    const groupId = await this.getGroupIdPartner();
    this.users = await userService.getAllUsers({
      params: {
        group_id: groupId,
      },
    });
  }

  public getEventColor(data: any) {
    return data.color;
  }

  public created() {
    this.getUsers();
  }
}
