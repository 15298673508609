export enum ReminderTo {
  ALL = 'ALL',
  GROUP = 'GROUP',
  ME = 'ME',
}

export enum ReminderType {
  CALL = 'CALL',
  ADMIN = 'ADMIN',
}

export enum ReminderColors {
  CALL = 'cyan',
  ADMIN = 'primary',
  ALL = 'primary',
  GROUP = 'info',
  ME = 'info',
  CONCLUDED = 'success',
  VISIT = 'deep-purple',
}
