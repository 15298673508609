import { User } from '@/modules/Administrative/modules/Users/entities/user.entity';
import moment from '@/services/moment/moment.service';
import reminderService from '@/modules/Clients/components/reminders/service/reminder.service';
import { ReminderColors, ReminderType } from '../enums/reminder.enum';

export class ClientReminder {
  public id: string;

  public name: string;

  public message: string;

  public to: number | string;

  public type: ReminderType;

  public active: number;

  public reminder_at: string;

  public order_id: string;

  public day: string;

  public hour: string;

  public concluded: boolean;

  public users: User[];

  constructor(data: any = {}) {
    this.id = data.id;
    this.name = data.title || data.name;
    this.message = data.message || '';
    this.to = data.to || '';
    this.type = data.type || ReminderType.ADMIN;
    this.active = data.active || '';
    this.concluded = !!data.finished_at;
    this.reminder_at = data.reminder_at || new Date();
    this.order_id = data.order_id || '';
    this.day = moment(this.reminder_at).format('YYYY-MM-DD');
    this.hour = moment(this.reminder_at).format('HH:mm') || moment(moment().toNow()).format('HH:mm');
    this.users = data.users && data.users.length ? data.users.map((it: any) => new User(it)) : [];
  }

  private formatToSave(): any {
    return {
      ...this,
      title: this.name,
      active: true,
      users: this.users.length ? this.users.map((user: User) => user.id) : undefined,
      reminder_at: this.formatDateToSave(),
    };
  }

  private formatDateToSave() {
    const day = moment(this.day).format('YYYY-MM-DD');
    return `${day} ${this.hour}:00`;
  }

  public formatToCalendar() {
    return {
      id: this.id,
      name: this.name,
      concluded: this.concluded,
      message: this.message,
      start: this.start,
      end: this.end,
      color: this.color,
    };
  }

  public get start() {
    return moment(this.reminder_at).format('YYYY-M-DD HH:mm');
  }

  public get end() {
    return moment(this.reminder_at).format('YYYY-M-DD HH:mm');
  }

  public get color() {
    const colors: any = ReminderColors;
    if (this.concluded) {
      return ReminderColors.CONCLUDED;
    }
    const typeColor = colors[this.type];
    return typeColor || colors[this.to];
  }

  public create(order_id: string) {
    const data = {
      ...this.formatToSave(),
      order_id,
    };
    return reminderService.createReminder(data);
  }

  public update() {
    return reminderService.updateReminder(this.formatToSave());
  }

  public delete() {
    return reminderService.deleteReminder(this.id);
  }

  public markAsConcluded() {
    return reminderService.markAsConcluded(this.id);
  }

  public markAsPending() {
    return reminderService.markAsPending(this.id);
  }
}
