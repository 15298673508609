
import { Component, Vue, Prop } from 'vue-property-decorator';
import DatePicker from '@/components/date-picker/date-picker.component.vue';
import moment from '@/services/moment/moment.service';
import TimePicker from '@/components/date-picker/time-picker.component.vue';
import { ClientReminder } from './entities/client-reminders.entity';
import { User } from '../../../Administrative/modules/Users/entities/user.entity';
import rulesService from '../../../../services/rules.service';
import { ReminderTo, ReminderType } from './enums/reminder.enum';

@Component({ components: { DatePicker, TimePicker } })
export default class ReminderDialog extends Vue {
  public $refs: any;

  @Prop({
    type: Boolean,
    default: () => false,
  })
  public readonly value!: boolean;

  @Prop({
    type: Object,
    default: () => new ClientReminder(),
  })
  public readonly reminder!: ClientReminder;

  @Prop({
    type: Array,
    default: () => [],
  })
  public readonly users!: User[];

  public rules = {
    required: rulesService.required,
    isValidHour: this.isValidHour,
  };

  private reminderType = ReminderType;

  public sending: boolean = false;

  public groups: any[] = [
    {
      id: ReminderTo.ME,
      name: 'Apenas para mim',
    },
    {
      id: ReminderTo.ALL,
      name: 'Criar lembrete para todos',
    },
    {
      id: ReminderTo.GROUP,
      name: 'Um grupo específico de usuários',
    },
  ];

  public get showUsersToSelect() {
    return this.reminder.to === ReminderTo.GROUP;
  }

  public isValidHour(hour: string) {
    if (!this.reminder.day) return '';

    return moment(`${this.reminder.day} ${hour}`, 'YYYY-MM-DD HH:mm').isSameOrAfter(
      moment().format('YYYY-MM-DD HH:mm'),
    );
  }

  public allowedHours(hour: string) {
    const day = moment(this.reminder.day, 'YYYY-MM-DD').format('YYYY-MM-DD');
    const minutes = moment(this.reminder.hour, 'HH:mm').format('mm');

    return moment(`${day} ${hour}`, 'YYYY-MM-DD HH').isSameOrAfter(
      moment().format('YYYY-MM-DD HH'),
    );
  }

  public allowedMinutes(minutes: string) {
    const today = moment().format('YYYY-MM-DD');
    const day = moment(this.reminder.day, 'YYYY-MM-DD').format('YYYY-MM-DD');
    const hour = this.reminder.hour ? moment(this.reminder.hour, 'HH:mm').format('HH') : '00';
    const isHourValid = this.allowedHours(hour);
    if (!isHourValid) {
      return true;
    }

    return moment(`${day} ${hour}:${minutes}`, 'YYYY-MM-DD HH:mm').isSameOrAfter(
      moment().format('YYYY-MM-DD HH:mm'),
    );
  }

  public allowedDays(value: string) {
    return moment(value, 'YYYY-MM-DD').isSameOrAfter(moment().format('YYYY-MM-DD'));
  }

  public confirm() {
    if (!this.$refs.form.validate()) return;
    this.sending = true;
    this.$emit('confirm');
  }

  public close() {
    this.$emit('close');
  }

  public setDay(date: string) {
    this.reminder.day = date;
    this.reminder.hour = '';
  }

  public get typeFormTitle() {
    return this.reminder.id ? 'Editar' : 'Criar um';
  }

  public get typeFormLabelButton() {
    return this.reminder.id ? 'Editar' : 'Criar';
  }
}
